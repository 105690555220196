<template>
  <ul
    style="z-index: 100;"
    class="fixed mr-2 mt-1 right-0 top-0 font-semibold flex flex-col"
  >
    <li
      v-if="errorMessage.length > 1"
      class="rounded-md mt-2 p-4 text-red-900 bg-white border-red-500 border-l-4 shadow-lg"
    >
      {{ errorMessage }}
    </li>
  </ul>
  <Loader v-if="processing">Arbejder...</Loader>
  <div
    class="w-full min-h-screen flex flex-col items-center justify-start relative"
  >
    <figure class=" h-24 mt-10">
      <img class="h-24" alt="Farmnet logo" src="@/assets/logo.svg" />
    </figure>
    <div
      class="md:w-4/5 flex flex-col items-center justify-center absolute top-24 mt-10 transition-all pt-6"
      :class="[form1 === true ? 'opacity-100 z-10' : 'opacity-0 z-0']"
    >
      <p class="text-3xl text-green mb-3">Velkommen</p>
      <p class="text-lg text-green w-96 text-center md:w-auto">
        Opret din virksomhed her og kom igang med det samme. Du får de første 30
        dage gratis.
      </p>
      <div class="flex flex-col md:flex-row my-6 w-full md:w-auto">
        <Input
          :error="errorCode === 'cvrNotValid' ? true : false"
          errorMessage="Ugyldigt cvr nr. Indtast 8 cifre"
          label="CVR"
          class="md:mr-6 mb-3 md:mb-0"
          labelWidth="w-32"
          inputWidth="w-64"
          placeholder="Indtast 8-cifret cvr. nr."
          v-model="clientData.cvr"
        />
        <Button
          label="Hent oplysninger fra CVR-registret"
          class="bg-gradient-to-r from-yellow-dark to-yellow mt-3 md:mt-0 w-96"
          @click="getCVR()"
        />
      </div>
      <p class="text-lg text-green md:mt-3 w-96 text-center md:w-auto">
        Indtast eller ret manglende oplysning.
      </p>
      <div class="flex flex-col md:flex-row my-3 md:my-6 w-full  md:w-auto">
        <Input
          :error="errorCode === 'companyNameNotValid' ? true : false"
          errorMessage="Indtast et gyldigt firmanavn. F.eks. 'Grønnegården'"
          label="Firmanavn"
          class="md:mr-6 mb-3 md:mb-0"
          labelWidth=" w-32 "
          inputWidth="w-64"
          placeholder=". . ."
          v-model="clientData.companyName"
        />
        <Input
          :error="errorCode === 'addressNotValid' ? true : false"
          errorMessage="Indtast en gyldig adresse. F.eks. 'Ribevej 18'"
          label="Adresse"
          class=""
          labelWidth=" w-32 "
          inputWidth="w-64"
          placeholder=". . ."
          v-model="clientData.address"
        />
      </div>
      <div class="flex flex-col md:flex-row mb-3 md:mb-6 w-full md:w-auto">
        <Input
          :error="errorCode === 'cityNotValid' ? true : false"
          errorMessage="Indtast et gyldigt bynavn. F.eks. 'Ribe'"
          label="By"
          class="md:mr-6 mb-3 md:mb-0"
          labelWidth=" w-32 "
          inputWidth="w-64"
          placeholder=" . . ."
          v-model="clientData.city"
        />
        <Input
          :error="errorCode === 'zipNotValid' ? true : false"
          errorMessage="Indtast et gyldigt post nr. F.eks. '6760'"
          label="Post nr."
          class=""
          labelWidth="w-32 "
          inputWidth="w-64"
          placeholder=". . ."
          v-model="clientData.zipcode"
        />
      </div>
      <div class="flex flex-col md:flex-row mb-3 md:mb-6 w-full md:w-auto">
        <Input
          :error="errorCode === 'firstNameNotValid' ? true : false"
          errorMessage="Indtast et gyldigt navn. F.eks. 'Jens'"
          label="Fornavn"
          class="md:mr-6 mb-3 md:mb-0"
          labelWidth=" w-32 "
          inputWidth="w-64"
          placeholder=" . . ."
          v-model="clientData.ownerFirstName"
        />
        <Input
          :error="errorCode === 'lastNameNotValid' ? true : false"
          errorMessage="Indtast et gyldigt navn. F.eks. 'Hansen'"
          label="Efternavn"
          class=""
          labelWidth=" w-32 "
          inputWidth="w-64"
          placeholder=". . ."
          v-model="clientData.ownerLastName"
        />
      </div>
      <div class="flex flex-col md:flex-row mb-3 md:mb-6 w-full md:w-auto">
        <Input
          :error="errorCode === 'emailNotValid' ? true : false"
          errorMessage="Indtast en gyldig email. F.eks. 'jens@hansen.dk'"
          type="email"
          label="E-mail"
          icon="fa-at"
          class="md:mr-6 mb-3 md:mb-0"
          labelWidth="w-32 "
          inputWidth="w-64"
          placeholder=". . ."
          v-model="clientData.ownerEmail"
        />
        <Input
          :error="errorCode === 'phoneNotValid' ? true : false"
          errorMessage="Indtast et gyldigt telefon nr. F.eks. '25 12 72 21'"
          type="tel"
          label="Telefon"
          icon="fa-phone"
          class=""
          labelWidth=" w-32 "
          inputWidth="w-64"
          placeholder=". . ."
          v-model="clientData.ownerPhone"
        />
      </div>

      <Button
        label="Næste"
        icon="fa-arrow-alt-circle-right"
        class="mt-6 w-3/4 md:w-1/3 bg-gradient-to-r from-yellow-dark to-yellow"
        @click="form1Next()"
      />
    </div>
    <div
      class="flex flex-col items-center justify-center absolute top-24 mt-10 transition-all pt-6"
      :class="[form2 === true ? 'opacity-100 z-10' : 'opacity-0 z-0']"
    >
      <p class="text-2xl text-green mb-6">Vi vil gerne hjælpe dig godt igang</p>
      <div class="flex flex-col md:flex-row mb-3 md:mb-6 w-full md:w-auto">
        <NumberInput
          class=""
          :error="errorCode === 'employeesNotValid' ? true : false"
          errorMessage="Indtast venligst hvor mange ansatte du har."
          placeholder="Hvor mange ansatte vil du lave tidsregistrering for?"
          labelWidth="w-36"
          inputWidth="w-96"
          label="Antal ansatte"
          v-model="meetingData.employees"
          min="1"
          max="100000"
        />
      </div>
      <div class="flex flex-col md:flex-row mb-3 md:mb-6 w-full md:w-auto">
        <Input
          :error="errorCode === 'typeNotValid' ? true : false"
          errorMessage="Indtast venligst en virksomhedstype"
          type="text"
          label="Virksomhedstype"
          placeholder="Hvilken type virksomhed har du?"
          labelWidth="w-40"
          inputWidth="w-80"
          v-model="clientData.type"
        />
      </div>
      <p class="text-lg text-green mt-6 md:mt-3 w-96 text-center">
        Vi vil gerne kontakte dig på telefon for at svare på spørgsmål og hjælpe
        med evt. udfordringer, men vi ved du kan have en travl hverdag.
      </p>
      <p class="text-lg text-yellow mt-6 md:mt-3 w-96 text-center md:w-auto">
        Hvornår vil det det passe dig bedst at vi kontakter dig?
      </p>
      <div class="flex mb-6 mt-3 w-full justify-evenly flex-grow-0">
        <div>
          <DatePicker
            v-model="meetingData.time"
            :min="now"
            width="w-52"
            ml="-ml-52"
            pr="pr-6"
            class="text-green-darkest bg-white"
          />
          <span class="text-green text-base">Fra Mandag - Fredag </span>
        </div>
        <div>
          <TimePicker
            v-model="meetingData.time"
            class="text-green-darkest bg-white"
            width="w-52"
            ml="-ml-52"
            pr="pr-6"
          />
          <span class="text-green text-base">Mellem kl. 8 og 17</span>
        </div>
      </div>
      <div class="flex w-full justify-between mt-6">
        <Button
          label="Tilbage"
          class=" w-32 bg-green"
          icon="fa-arrow-alt-circle-left"
          @click="
            form1 = !form1;
            form2 = !form2;
          "
        />
        <Button
          class="w-32 bg-gradient-to-r from-yellow-dark to-yellow"
          label="Opret"
          icon="fa-check-circle"
          @click="createNewFarmerFunctions(clientData)"
        />
      </div>
    </div>
    <div
      class="flex flex-col items-center justify-center absolute top-24 mt-10 transition-all pt-6"
      :class="[thankYou === true ? 'opacity-100 z-10' : 'opacity-0 z-0']"
    >
      <p class="text-2xl text-yellow mb-6">Tak!</p>
      <p class="text-lg text-green mt-6 md:mt-3 w-96 text-center">
        Din bruger er nu blevet oprettet og en mail med login-oplysninger er
        sendt afsted. Er mailen ikke i din indbakke inden for kort tid, så tjek
        venligst dit spam filter.
      </p>
      <p class="text-lg text-green mt-6 md:mt-3 w-96 text-center">
        Du vil om lidt blive ført tilbage til login siden.
      </p>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import Input from "@/components/Input";
import NumberInput from "@/components/NumberInput";
import DatePicker from "@/components/DatePicker";
import TimePicker from "@/components/TimePicker";
import Loader from "@/components/Loader";
import { ref } from "vue";
import { useRouter } from "vue-router";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/auth";
import { isMobile } from "@/global/services/mixins";
import {
  validateNumbers,
  validateText,
  validateTextNumbers,
  validateEmail,
  validatePhone
} from "@/global/services/mixins";
import { systemState } from "@/global/services/systemState";
import { errorLogging } from "@/global/services/errorLogging";

export default {
  name: "page-create",
  components: {
    Button,
    Input,
    NumberInput,
    DatePicker,
    TimePicker,
    Loader
  },
  setup() {
    const now = new Date();
    now.setDate(now.getDate() + 1);
    const meetingPlaceHolder = new Date();
    meetingPlaceHolder.setDate(meetingPlaceHolder.getDate() + 1);
    if (meetingPlaceHolder.getDay() === 6) {
      meetingPlaceHolder.setDate(meetingPlaceHolder.getDate() + 2);
    } else if (meetingPlaceHolder.getDay() === 0) {
      meetingPlaceHolder.setDate(meetingPlaceHolder.getDate() + 1);
    }
    meetingPlaceHolder.setHours(10, 0);
    const state = {
      now: ref(now.getTime()),
      processing: ref(false),
      errorMessage: ref(""),
      form1: ref(true),
      form2: ref(false),
      thankYou: ref(false),
      clientData: ref({
        companyName: "",
        cvr: "",
        ownerFirstName: "",
        ownerLastName: "",
        ownerPhone: "",
        ownerEmail: "",
        address: "",
        city: "",
        zipcode: null,
        type: ""
      }),
      meetingData: ref({
        employees: 0,
        time: meetingPlaceHolder.getTime()
      }),
      errorCode: ref("")
    };

    document.addEventListener("processing", event => {
      state.processing.value = event.detail;
    });

    const router = useRouter();

    const functions = {
      getCVR: async function() {
        if (!/^(?<!\d)\d{8}(?!\d)$/.test(state.clientData.value.cvr)) {
          state.errorCode.value = "cvrNotValid";
        } else {
          state.errorCode.value = "";
          const promise = fetch(
            "https://cvrapi.dk/api?search=" +
              state.clientData.value.cvr +
              "&country=dk"
          );
          /* eslint-disable prefer-const */
          let res;
          [res] = await Promise.all([promise]);
          const jPromise = res.json();
          let data;
          [data] = await Promise.all([jPromise]);
          state.clientData.value.companyName = data.name;
          state.clientData.value.address = data.address;
          state.clientData.value.city = data.city;
          state.clientData.value.zipcode = data.zipcode;
          state.clientData.value.ownerEmail = data.email;
          state.clientData.value.ownerPhone = data.phone;
          const owner = data.owners[0].name;
          state.clientData.value.ownerFirstName = owner.substr(
            0,
            owner.indexOf(" ")
          );
          state.clientData.value.ownerLastName = owner.substr(
            owner.indexOf(" ") + 1
          );

          state.meetingData.value.employees = parseInt(data.employees);
        }
      },

      form1Next: function() {
        if (!/^(?<!\d)\d{8}(?!\d)$/.test(state.clientData.value.cvr)) {
          state.errorCode.value = "cvrNotValid";
        } else if (!validateText(state.clientData.value.companyName)) {
          state.errorCode.value = "companyNameNotValid";
        } else if (!validateTextNumbers(state.clientData.value.address)) {
          state.errorCode.value = "addressNotValid";
        } else if (!validateText(state.clientData.value.city)) {
          state.errorCode.value = "cityNotValid";
        } else if (!validateNumbers(state.clientData.value.zipcode)) {
          state.errorCode.value = "zipNotValid";
        } else if (!validateText(state.clientData.value.ownerFirstName)) {
          state.errorCode.value = "firstNameNotValid";
        } else if (!validateText(state.clientData.value.ownerLastName)) {
          state.errorCode.value = "lastNameNotValid";
        } else if (!validateEmail(state.clientData.value.ownerEmail)) {
          state.errorCode.value = "emailNotValid";
        } else if (!validatePhone(state.clientData.value.ownerPhone)) {
          state.errorCode.value = "phoneNotValid";
        } else {
          state.errorCode.value = "";
          state.form1.value = !state.form1.value;
          state.form2.value = !state.form2.value;
        }
      },

      createNewFarmerFunctions: function() {
        const data = {
          clientData: state.clientData.value,
          meetingData: state.meetingData.value
        };
        const f = firebase.functions().httpsCallable("createFarmer");
        if (!validateNumbers(state.meetingData.value.employees)) {
          state.errorCode.value = "employeesNotValid";
        } else if (!validateText(state.clientData.value.type)) {
          state.errorCode.value = "typeNotValid";
        } else {
          systemState.startProcessing();
          state.errorCode.value = "";
          f(data)
            .then(result => {
              console.log(result.data);
              if (result.data.code === 200) {
                state.form2.value = !state.form2.value;
                state.thankYou.value = !state.thankYou.value;
                setTimeout(() => router.push({ name: "Login" }), 10000);
              } else if (result.data.code === "auth/email-already-exists") {
                state.errorMessage.value =
                  "Der findes allerede en bruger for den angivne mail.";
                setTimeout(() => (state.errorMessage.value = ""), 5000);
              } else {
                state.errorMessage.value =
                  "Der er sket en fejl. Venligst kontakt support på telfon 25127221.";
                errorLogging.setError(result.data, "Line 396", "Create");
              }
              systemState.stopProcessing();
            })
            .catch(result => {
              console.log(result.data);
              systemState.stopProcessing();
            });
        }
      }
    };

    function isIOS() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod"
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    }

    function isPWA() {
      return window && window.matchMedia("(display-mode: standalone)").matches;
    }

    const getApp = () => {
      isIOS()
        ? (state.getAppIOS.value = !state.getAppIOS.value)
        : (state.getAppAndroid.value = !state.getAppAndroid.value);
    };

    return { ...state, ...functions, isMobile, getApp, isPWA };
  }
};
</script>

<style lang="scss" scoped></style>
